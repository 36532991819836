import React from 'react';
import Card from 'react-bootstrap/Card';

export const Bottom = () => {
  return (
    <Card className='bottom'>
      <Card.Body>
      Vom Autor: Die obige Seite ist eine Demonstration der Fähigkeiten beim Erstellen von Onepage-Seiten. 
      Alle grafischen Elemente wurden von mir mit Technologien erstellt: 
      React-Bootstrap, SVG, ReactHooks, FramerMotion oder in Raster- und Vektorgrafiken. 
      Wenn Sie Fragen zu dieser Website oder zur Zusammenarbeit mit mir haben, 
      wenden Sie sich bitte an: ollo7776@gmail.com
      </Card.Body>
    </Card>
  );
}
