import React, { useState, useEffect } from 'react'
// import { motion, useAnimation } from "framer-motion";
import { motion, useAnimation } from "framer-motion/dist/framer-motion";

export const CookieMonster = () => {
    const controlsBody = useAnimation();
    const controlsFreckles = useAnimation();
    // const controlsEye = useAnimation();
    const [isMove, setIsMOve] = useState(true)
    const repeatAnimation = () => {
        setIsMOve(!isMove)
    }

    const variantBody = {
        initial: {
            rotate: 0,
        }
    }
    useEffect(() => {
        if (isMove) {
            controlsBody.start({
                rotate: [3600, 0],
                transition: { duration: 6 },
            })
        }
        else {
            controlsBody.start({
                rotate: [0, 3600],
                transition: { duration: 6, },
            })
        }
    }, [isMove, controlsBody])

    const variantsFreckles = {
        initial: {
            rotate: 0,
        }
    }
    useEffect(() => {
        if (isMove) {
            controlsFreckles.start({
                scale: [1, 1, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.5, 1.4, 1.3, 1.2, 1.1, 1],
                rotate: [3600, 0],
                transition: { duration: 6.6 },
            })
        }
        else {
            controlsFreckles.start({
                scale: [1, 1, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.5, 1.4, 1.3, 1.2, 1.1, 1],
                rotate: [0, 3600],
                transition: { duration: 6.6, },
            })
        }
    }, [isMove, controlsFreckles])

    
    return (
        <>
            {/* SVG COOKIEMONSTER */}
            <svg className='cookies-monster'
                viewBox="-2 -2 100 100"
                width='120px' height='120px'>
                {/* cookie Monster Body */}
                <motion.path
                    d="M 40 35 L 15 35 C 15 5 70 0 70 40 C 70 70 30 75 20 60 L 40 35 "
                    //d="M 40 35 L 15 35 C 15 5 70 0 70 40 C 70 70 30 75 20 60 L 40 35"
                    fill="brown"
                    strokeWidth="1.7"
                    stroke="rgb(160, 84, 84)"
                    //stroke="transparent"
                    strokeLinecap="round"
                    variants={variantBody}
                    initial='initial'
                    animate={controlsBody}
                    onClick={repeatAnimation}
                />
                {/* Monster freckles*/}
                <motion.path
                    d="M 34 18 L 19 30 C 27 34 28 32 34 18  M 23 29 C 24 28.3333 25 27.6667 26 27 C 26 28 26 29 24 30  M 41 19 C 45 19 45 13 41 13 C 37 13 37 19 41 19 M 41 22 C 41 20 38 20 38 22 C 38 24 41 24 41 22 M 55 20 C 55 17 51 17 51 20 C 51 23 55 23 55 20 M 62 23 C 62 21 59 21 59 23 C 59 25 62 25 62 23 M 46 30 C 46 27 41 27 41 30 C 41 33 46 33 46 30 M 58 30 C 58 25 51 25 51 30 C 51 35 58 35 58 30 M 63 39 C 63 37 60 37 60 39 C 60 41 63 41 63 39 M 40 55 C 40 49 32 49 32 55 C 32 60 40 60 40 55 M 56 58 C 56 54 50 54 50 58 C 50 62 56 62 56 58 M 52 44 C 52 40 46 40 46 44 C 46 48 52 48 52 44 M 23 29 C 24 28.3333 25 27.6667 26 27 C 26 28 26 29 24 30 M 44 38"
                    fill="grey"
                    strokeWidth="0.5"
                    // stroke="lightblue"
                    stroke="black"
                    strokeLinecap="round"
                    variants={variantsFreckles}
                    initial='initial'
                    animate={controlsFreckles}
                />

                {/* Monster eye*/}
                {/* <motion.path
                    d="M 34 18 L 19 30 C 27 34 28 32 34 18  M 23 29 C 24 28.3333 25 27.6667 26 27 C 26 28 26 29 24 30 "
                    fill="grey"
                    strokeWidth="0.5"                    
                    stroke="black"
                    strokeLinecap="round"
                    variants={variantsFreckles}
                    initial='initial'
                    animate={controlsFreckles}
                /> */}
            </svg>
            Click on the monster!
        </>
    )
}
