import React, {useContext} from 'react'
import { DataContext } from '../data-context/DataContext';
import Table from 'react-bootstrap/Table';

export const Q4Table = () => {
    // Data from DataContext GLOBAL VARIABLE
    const { form } = useContext(DataContext)
    const { gutachten } = useContext(DataContext)
    
    return (
        <>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th> {form.vorName}</th>
                        <th>{form.nachName}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>von - bis</td>
                        <td>{form.von}</td>
                        <td>{form.bis}</td>
                    </tr>
                    <tr>
                        <td>Freistellung</td>
                        <td colSpan={2}>{form.freistellung}</td>
                    </tr>
                    <tr>
                        <td>Gutachten</td>
                        <td colSpan={2}>
                            {gutachten.map((i) => {
                                return <li key={i} >{i}<br /></li>
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>Pflegegrad (bisheriger)</td>
                        <td colSpan={2}>
                            {form.pflegegrad}
                        </td>
                    </tr>
                    <tr>
                        <td>Dein Kommentar</td>
                        <td colSpan={2}>
                            {form.comment}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}
