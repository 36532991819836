import React, { useContext, useState } from "react"

const PageContext = React.createContext()
const BackContext = React.createContext()
const PageUpdateRight = React.createContext()
const PageUpdateLeft = React.createContext()

export function usePage() {
    return useContext(PageContext)
}
// A boolean that return true after click on back button
export function useBack() {
    return useContext(BackContext)
}

export function usePageRight() {
    return useContext(PageUpdateRight)
}
export function usePageLeft() {
    return useContext(PageUpdateLeft)
}

export function PageProvider({ children }) {
    const [pageNo, setPageNo] = useState(1)
    const [backClick, setBackClick] = useState(false)

    function changePageRight() {
        setPageNo(pageNo + 1)
        setBackClick(false)
    }
    function changePageLeft() {
        setPageNo(pageNo - 1)
        setBackClick(true)
    }

    return (
        <PageContext.Provider value={pageNo} >
            <BackContext.Provider value={backClick} >
                <PageUpdateRight.Provider value={changePageRight}>
                    <PageUpdateLeft.Provider value={changePageLeft} >
                        {children}
                    </PageUpdateLeft.Provider>
                </PageUpdateRight.Provider>
            </BackContext.Provider>
        </PageContext.Provider >
    )
}