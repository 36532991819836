import React, { useState, useEffect, useContext } from 'react'
import { motion, useAnimation } from "framer-motion/dist/framer-motion";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import FormLabel from 'react-bootstrap/FormLabel';
//import Alert from 'react-bootstrap/Alert';
//import Table from 'react-bootstrap/Table';
import { useBack, usePage, usePageRight } from './PageContext'
import { DataContext } from '../data-context/DataContext';

//import Q1Select from './Q1Select';
//import { Q1Radio } from './Q1Radio';

export const Q1 = () => {
    // useContext variable and methods
    const updatePageRight = usePageRight()
    const pageNo = usePage()
    const backPressed = useBack()
    const timeAwait = 450

    // Data from DataContext GLOBAL VARIABLE
    const { form, setForm, errors, setErrors } = useContext(DataContext)

    // Variable for radio check buttons - one option
    const [radioValue, setRadioValue] = useState(null)
    const radios = [
        { name: '25%', value: '25%', var: '1', },
        { name: '50%', value: '50%', var: '2', },
        { name: '75%', value: '75%', var: '3', },
        { name: '100%', value: '100%', var: '4', },
    ];

    const setField = (field, value) => {
        setForm({
            ...form, [field]: value
        })
        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }

    const validateForm = () => {
        const { vorName, nachName, von, bis, freistellung } = form
        const newErrors = {}
        if (!vorName || vorName === '') newErrors.vorName = 'Vorname muss ausgefüllt werden!'
        if (!nachName || nachName === '') newErrors.nachName = 'Nachname muss ausgefüllt werden!'
        if (!von || von === '') newErrors.von = 'Dies muss ausgefüllt werden!'
        if (!bis || bis === '') newErrors.bis = 'Dies muss ausgefüllt werden!'
        if (!freistellung || freistellung === '') newErrors.freistellung = 'Eine Option muss ausgefüllt werden!'

        return newErrors
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(form)
        console.log(errors)
        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else {
            console.log('form submitted')
            console.log(form)
            setMoveLeft(true);
        }
    }

    const handleSubmitNoValid = (e) => {
        setMoveLeft(true)
    }

    const handleChangeRadio = e => {
        setRadioValue(e.target.value)
        setField('freistellung', e.target.value)
    }

    //------------------------------ANIMATIONS---------------------------------------------
    // Framer motion animation variables
    const controls = useAnimation()
    const [moveLeft, setMoveLeft] = useState()

    // PAGE MOVE
    // function moveLeftFunc() {
    //     if (validated) {
    //         setMoveLeft(state => !state)
    //     }
    // }
    // useEffect(() => {
    //     if (valid) {
    //         setMoveLeft(state => !state)
    //     }
    // }, [valid])


    // animations settings on initial state
    const variants = {
        initial: {
            x: backPressed ? -1400 : 0,
        }
    }
    // animation after right click on button 'weiter'
    useEffect(() => {
        if (moveLeft) {
            controls.start({
                x: [0, -1400],
                transition: { duration: 1 }
            })
            const timerCardOut = setInterval(() => {
                updatePageRight()
            }, timeAwait);
            return () => {
                clearInterval(timerCardOut);
            }
        }
    }, [moveLeft, controls, updatePageRight])

    // animation after load and back Click on previous site
    useEffect(() => {
        if (backPressed) {
            controls.start({
                x: [-1400, 0],
                transition: { duration: 1 }
            })
        }
    }, [backPressed, controls])

    return (
        <motion.div
            variants={variants}
            initial='initial'
            animate={controls}
        >
            <Card className='cards'>
                <Card.Body>
                    <Card.Title><Badge bg="primary">{pageNo}</Badge> Antrag auf Pflegezeit gemäß § 3 Pflegezeitgesetz </Card.Title>
                    <Card.Text>
                        Hiermit kündige ich die Inanspruchnahme einer Pflegezeit für
                        die Pflege in häuslicher Umgebung an.
                        Die Voraussetzungen für die Gewährung der
                        Freistellung liegen vor.<br /><br />
                    </Card.Text>
                    <Form>
                        <Card className='cards-form'>
                            <Card.Body className='cards-body'>
                                Bei der zu pflegenden Person handelt es sich um:
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId='vorName'>
                                        <FormLabel>Vorname</FormLabel>
                                        <Form.Control
                                            type='text'
                                            required
                                            placeholder='Vorname'
                                            value={form.vorName || ''}
                                            onChange={(e) => setField('vorName', e.target.value)}
                                            isInvalid={!!errors.vorName}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.vorName}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId='nachName'>
                                        <FormLabel>Nachname</FormLabel>
                                        <Form.Control
                                            type='text'
                                            required
                                            placeholder='Nachname'
                                            value={form.nachName || ''}
                                            onChange={(e) => setField('nachName', e.target.value)}
                                            isInvalid={!!errors.nachName}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.nachName}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className='cards-form'>
                            <Card.Body className='cards-body'>
                                Bei der zu pflegenden Person handelt es sich um:
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId='von'>
                                        <FormLabel>von</FormLabel>
                                        <Form.Control
                                            type='date'
                                            required
                                            placeholder='von'
                                            value={form.von || ''}
                                            onChange={(e) => setField('von', e.target.value)}
                                            isInvalid={!!errors.von}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.von}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId='bis'>
                                        <FormLabel>bis</FormLabel>
                                        <Form.Control
                                            type='date'
                                            required
                                            placeholder='bis'
                                            value={form.bis || ''}
                                            onChange={(e) => setField('bis', e.target.value)}
                                            isInvalid={!!errors.bis}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.bis}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                            </Card.Body>
                        </Card>

                        <Card>
                            <Card.Body className='cards-body'>
                                Ich beantrage eine teilweise oder 100% Freistellung:
                                <br />
                                <Form.Group controlId='freistellung'>
                                    <ButtonGroup className='form-group-radio' >
                                        {radios.map((radio, idx) => (
                                            <ToggleButton
                                                className='form-group-radio-button'
                                                key={idx}
                                                id={`radio-${idx}`}
                                                type="radio"
                                                variant={radioValue === radio.value ? 'success' : radioValue === 0 ? 'outline-danger' : 'outline-success'}
                                                name="freistellung"
                                                value={radio.value || ''}
                                                checked={radioValue === radio.var}
                                                onChange={handleChangeRadio}
                                                required
                                            >
                                                {radio.name}
                                            </ToggleButton >
                                        ))}
                                    </ButtonGroup>
                                    <br />
                                    {errors.freistellung ? <span style={{ color: '#dc3545', fontWeight: 'bold' }}> {errors.freistellung}</span> : ''}                                    
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Form>
                    <div className='submit-buttons'>
                        <Button variant="success" disabled>zurück</Button>&nbsp;                       
                        <Button variant="success" type='submit' onClick={handleSubmit}>weiter</Button>                                              
                    </div>
                    <br/><br/>
                        <div className='submit-buttons'>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="no validation"
                                onClick={handleSubmitNoValid}
                            />
                            </div>

                </Card.Body >
            </Card >
        </motion.div >
    )
}
