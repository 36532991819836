import React, { createContext, useState } from 'react'

export const DataContext = createContext()

export const DataProvider = ({ children }) => {
    // Global variables for uncontrolled input
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    // const [data, setData] = useState({
    //     vorName: 'Jan',
    //     nachName: 'Kowalski',
    //     von: '01-03-2022',
    //     bis: '01-03-2023',
    //     freistellung: null,
    // })
    const [checkedArr, setCheckedArr] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,false])
    const [gutachten, setGutachten] = useState([])
   
    return (
        <DataContext.Provider value={{ errors, setErrors, form, setForm, gutachten, setGutachten, checkedArr, setCheckedArr }}>
            {children}
        </DataContext.Provider>
    )

}