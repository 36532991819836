import React, { useState, useEffect } from 'react'
import { motion, useAnimation } from "framer-motion/dist/framer-motion";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { usePage, useBack, usePageLeft, usePageRight } from './PageContext'
import { Q4Table } from './Q4Table';

export const Q4Send = () => {
    // useContext variable and methods
    const updatePageRight = usePageRight()
    const updatePageLeft = usePageLeft()
    const pageNo = usePage()
    const backPressed = useBack()
    const timeAwait = 450

    // Framer motion animation variables
    const controls = useAnimation()
    const [moveRight, setMoveRight] = useState()
    const [moveLeft, setMoveLeft] = useState()

    // animations settings on initial state
    const variants = {
        initial: {
            x: -1400
        }
    }
    // animation after load and forward Click on previous site
    controls.start({
        x: [1400, 0],
        transition: { duration: 1 }
    })

    // animation after load and back Click on previous site
    useEffect(() => {
        if (backPressed) {
            controls.start({
                x: [-1400, 0],
                transition: { duration: 1 }
            })
        }
    }, [backPressed, controls])

    // animation after right click on button 'weiter'
    useEffect(() => {
        if (moveLeft) {
            controls.start({
                x: [0, -1400],
                transition: { duration: 1 }
            })
            const timerCardOut = setInterval(() => {
                updatePageRight()
            }, timeAwait);
            return () => {
                clearInterval(timerCardOut);
            }
        }
    }, [moveLeft, controls, updatePageRight])

    // animation after left button click 'zurück'
    useEffect(() => {
        if (moveRight) {
            controls.start({
                x: [0, 1400],
                transition: { duration: 1 }
            })
            const timerCardOut = setInterval(() => {
                updatePageLeft()
            }, timeAwait);
            return () => {
                clearInterval(timerCardOut);
            }
        }
    }, [moveRight, controls, updatePageLeft])

    return (
        <motion.div
            variants={variants}
            initial='initial'
            animate={controls}
        >
            <Card className='cards'>
                <Card.Body>
                    <Card.Title><Badge bg="primary">{pageNo}</Badge> DATEN CHECK </Card.Title>
                    {/* <Card.Text > */}
                        Vielen Dank für die Teilnahme an der Umfrage.
                        Nachfolgend finden Sie eine Zusammenfassung der von Ihnen bereitgestellten Daten.<br />
                        <Q4Table />
                        Wenn Sie alle Daten angegeben haben und das Formular absenden möchten, klicken Sie auf Absenden.
                        Wenn Sie noch Änderungen vornehmen möchten, klicken Sie auf Zurück.
                        Nach dem Absenden des Antrags erhalten Sie eine Eingangsbestätigung des Dokuments
                        sowie Informationen zum Bearbeitungsvorgang und den Fristen zur Prüfung des Antrags.
                        <br />
                    {/* </Card.Text> */}
                    <div className='submit-buttons'>
                        <Button variant="success" onClick={() => setMoveRight(state => !state)}>zurück</Button>&nbsp;
                        <Button variant="success" onClick={() => setMoveLeft(state => !state)}>Abschicken</Button>
                    </div>
                </Card.Body>
            </Card>
        </motion.div>
    )
}
