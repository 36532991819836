import React from 'react';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
//import CircleBadge from '../icons/CircleBadge';
import { usePage } from './PageContext'
import { HeadTitel } from './HeadTitel';
import { ModalInfo } from './ModalInfo';
import EsculapeInCircle from '../icons/EsculapeInCircle';

export const Head = () => {
  const pageNo = usePage()
  const nowValue = pageNo * 25 > 100 ? 100 : pageNo * 25
  const now = 'progress: ' + nowValue
  return (
    <div className='head-div'>
      <Card className='head'>
        <Card.Body className='head-body'>
          {/* <CircleBadge /> */}
          <EsculapeInCircle />

          {/* TITLE: PFLEGEGRAD BEFRAGUNG */}
          <HeadTitel />
          <ModalInfo />
        </Card.Body>
      </Card>
      <span className='progress-bar'><ProgressBar animated now={nowValue} label={`${now}%`} /></span>      
    </div>
  );
}
