import React, { useState, useEffect, useContext } from 'react'
import { motion, useAnimation } from "framer-motion/dist/framer-motion";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import FormLabel from 'react-bootstrap/FormLabel';
import { usePage, useBack, usePageLeft, usePageRight } from './PageContext'
import { DataContext } from '../data-context/DataContext';

export const Q3 = () => {
    // useContext global variable and methods
    const updatePageRight = usePageRight()
    const updatePageLeft = usePageLeft()
    const pageNo = usePage()
    const backPressed = useBack()
    const timeAwait = 450
    const { pflegegrad } = useContext(DataContext)

    // Framer motion animation variables
    const controls = useAnimation()
    const [moveRight, setMoveRight] = useState()
    const [moveLeft, setMoveLeft] = useState()

    // animations settings on initial state
    const variants = {
        initial: {
            x: -1400
        }
    }

    // animation after load and forward Click on previous site
    useEffect(() => {
        controls.start({
            x: [1400, 0],
            transition: { duration: 1 }
        })
    }, [])



    // animation after load and back Click on previous site
    useEffect(() => {
        if (backPressed) {
            controls.start({
                x: [-1400, 0],
                transition: { duration: 1 }
            })
        }
    }, [backPressed, controls])

    // animation after right click on button 'weiter'
    useEffect(() => {
        if (moveLeft && pflegegrad !== null) {
            controls.start({
                x: [0, -1400],
                transition: { duration: 1 }
            })
            const timerCardOut = setInterval(() => {
                updatePageRight()
            }, timeAwait);
            return () => {
                clearInterval(timerCardOut);
            }
        }
    }, [moveLeft, controls, updatePageRight, pflegegrad])

    // animation after left button click 'zurück'
    useEffect(() => {
        if (moveRight) {
            controls.start({
                x: [0, 1400],
                transition: { duration: 1 }
            })
            const timerCardOut = setInterval(() => {
                updatePageLeft()
            }, timeAwait);
            return () => {
                clearInterval(timerCardOut);
            }
        }
    }, [moveRight, controls, updatePageLeft])

    // const handleClick = (e) => {
    //     e.preventDefault()
    //     e.stopPropagation()
    //     if (pflegegrad !== null) {
    //         setMoveLeft(state => !state)
    //     }
    // }


    //----------------DATA CONTEXT-----------------------------------------------------------
    // Data from DataContext GLOBAL VARIABLE
    const { form, setForm, errors, setErrors } = useContext(DataContext)

    const setField = (field, value) => {
        setForm({
            ...form, [field]: value
        })
        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }
    const validateForm = () => {
        const { pflegegrad } = form
        const newErrors = {}
        if (!pflegegrad || pflegegrad === '') newErrors.pflegegrad = 'Pflegegrad muss gewählt werden!'        

        return newErrors
    }

    const handleChangeSelect = e => {
        setField('pflegegrad', e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(form)
        console.log(errors)
        const formErrors = validateForm()
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else {
            console.log('form submitted')
            console.log(form)
            setMoveLeft(true);
        }
    }


    return (
        <motion.div
            variants={variants}
            initial='initial'
            animate={controls}
        >

            <Card className='cards'>
                <Card.Body>
                    <Card >
                        <Card.Body className='cards-body'>
                            <Card.Title><Badge bg="primary">{pageNo}</Badge> DRITTE SCHRITT </Card.Title>
                            <Card.Text>
                                Bitte füllen Sie die entsprechenden Felder des unten stehenden Formulars aus.
                            </Card.Text>

                            <Form>
                                <Form.Group className="mb-3" controlId="pflegegrad">
                                <FormLabel>Wie hoch war Ihr bisheriger Pflegegrad?</FormLabel>
                                    <Form.Select
                                        aria-label="Wie hoch war Ihr bisheriger Pflegegrad?"                                        
                                        value={form.pflegegrad || ''}
                                        onChange={handleChangeSelect}
                                    >
                                        <option>Wie hoch war Ihr bisheriger Pflegegrad?</option>
                                        <option value="Pflegegrad 1: geringe Beeinträchtigungen.">Pflegegrad 1: geringe Beeinträchtigungen.</option>
                                        <option value="Pflegegrad 2: erhebliche Beeinträchtigungen.">Pflegegrad 2: erhebliche Beeinträchtigungen.</option>
                                        <option value="Pflegegrad 3: schwere Beeinträchtigungen.">Pflegegrad 3: schwere Beeinträchtigungen.</option>
                                        <option value="Pflegegrad 4: schwerste Beeinträchtigungen.">Pflegegrad 4: schwerste Beeinträchtigungen.</option>
                                        <option value="Pflegegrad 5: schwerste Beeinträchtigungen, mit besonderen Anforderungen an die pflegerische Versorgung.">Pflegegrad 5: schwerste Beeinträchtigungen, mit besonderen Anforderungen an die pflegerische Versorgung.</option>
                                    </Form.Select>
                                    {errors.pflegegrad ? <span style={{ color: '#dc3545', fontWeight: 'bold' }}> {errors.pflegegrad}</span> : ''}
                                </Form.Group>


                                <Form.Group className="mb-3" controlId="comments">
                                    Wenn Sie Ihre Meinung mitteilen möchten oder eine weitere Nachricht für uns haben,
                                    füllen Sie bitte das untenstehende Formular aus.
                                    <FormLabel>Kommentar</FormLabel>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Leave a comment here"
                                        style={{ height: '100px' }}
                                        name='comment'
                                        value={form.comment || ''}
                                        onChange={(e) => setField('comment', e.target.value)}
                                    />
                                </Form.Group>
                            </Form>

                        </Card.Body>
                    </Card>
                            <div className='submit-buttons'>
                                <Button variant="success" onClick={() => setMoveRight(state => !state)}>zurück</Button>&nbsp;
                                {/* <Button variant="success" onClick={() => setMoveLeft(state => !state)}>weiter</Button> */}
                                <Button variant="success" onClick={handleSubmit}>weiter</Button>
                            </div>                        
                </Card.Body>
            </Card >
        </motion.div >
    )
}
