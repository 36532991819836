import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { motion, useAnimation } from "framer-motion/dist/framer-motion";

export const ModalInfo = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Framer motion animation variables
    const controlsL = useAnimation();
    const controlsR = useAnimation();
    const controlsINFO = useAnimation();
    const [isMove, setIsMove] = useState(true);

    //Repeating interval for all with 'isMove' animations effects
    useEffect(() => {
        const timerCardOut = setInterval(() => {
            setIsMove(state => !state)
        }, 5000);
        return () => {
            clearInterval(timerCardOut);
        }
    }, [setIsMove])

    // EFFECT FOR LETTERS 'INFO'
    const variantsINFO = {
        initial: {
            opacity: 1,
        }
    }
    useEffect(() => {
        if (isMove) {
            controlsINFO.start({
                y: [0, 10, 10, 0, 0],
                transition: { duration: 2, times: [0, 0.1, 0.5, 0.6, 2] },
            })
        }
    }, [isMove, controlsINFO])


    // Initial state for left char '<'
    const variantsL = {
        initial: {
            opacity: 1,
        }
    }
    // Initial state for right char '>'
    const variantsR = {
        initial: {
            opacity: 1,
        }
    }
    // EFFECT FOR '<'
    useEffect(() => {
        if (isMove) {
            controlsL.start({
                x: [0, -10, -10, 0, 0],
                transition: { duration: 2, times: [0, 0.1, 0.6, 0.7, 2] },
            })
        }
    }, [isMove, controlsL])

    // EFFECT FOR '>'
    useEffect(() => {
        if (isMove) {
            controlsR.start({
                x: [0, 10, 10, 0, 0],
                transition: { duration: 2, times: [0, 0.1, 0.7, 0.8, 2] },
            })
        }
    }, [isMove, controlsR])

    return (
        <>
            <div className='modal-info-background'>
                {/* Trigger button INFO with animation */}
                <Button variant="primary" onClick={handleShow} className='modal-info-button'>
                    <motion.div className='modal-text-element'
                        variants={variantsL}
                        initial='initial'
                        animate={controlsL}
                    >&lt;</motion.div>
                    <motion.div className='modal-text-element'
                        variants={variantsINFO}
                        initial='initial'
                        animate={controlsINFO}
                    >INFO</motion.div>
                    <motion.div className='modal-text-element'
                        variants={variantsR}
                        initial='initial'
                        animate={controlsR}
                    >&gt;</motion.div>
                </Button>
            </div>
            {/* Modal with infos about the page */}
            <Modal show={show} onHide={handleClose}>
                {/* <Modal.Header closeButton className='modal-title'> */}
                <Modal.Header className='modal-title'>
                    <Modal.Title ></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>This is a test website!</h4>
                    <p className='modal-text'>  You can freely enter various data into the forms.
                        The site does not remember or send any messages.
                        The website is created only for testing purposes
                        and to show in which technologies I write programs.
                        On this site I've used
                        <span style={{ fontWeight: 'bold' }}>
                            <li>React-Bootstrap,</li>
                            <li> React-Hooks</li>
                            <li> FramerMotion for animation,</li>
                            <li> CSS HTML.</li>
                        </span>
                        In React I've used Hooks such as;<br />
                        useState, useContext, useEffect.<br /><br />
                        The website is on the Apache2 server on the UBUNTU system.<br />
                        The website is secured using let's encrypt SSL certificate.
                        <br />
                        <span style={{ fontWeight: 'bold' }}>Server space is purchased on IONOS for € 1 per month!</span><br />
                        (Server parameters: VPS - CPU 1 vCore, RAM 512MB, SSD 10GB)
                        <br />
                        There are no cookies on this Website
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="primary" onClick={handleClose}>
                        Ok, no COOKIES (Close)
                    </Button> */}
                    <div className='modal-close-background'>
                        {/* Trigger button INFO with animation */}
                        <Button variant="primary" onClick={handleClose} className='modal-info-button'>
                            <motion.div className='modal-text-element'
                                variants={variantsL}
                                initial='initial'
                                animate={controlsL}
                            >&lt;</motion.div>
                            <motion.div className='modal-text-element'
                                variants={variantsINFO}
                                initial='initial'
                                animate={controlsINFO}
                            >CLOSE</motion.div>
                            <motion.div className='modal-text-element'
                                variants={variantsR}
                                initial='initial'
                                animate={controlsR}
                            >/&gt;</motion.div>
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
