import React from 'react'
import { Q1 } from './Q1'
import { Q2 } from './Q2'
import { Q3 } from './Q3'
import { Q4Send } from './Q4Send'
import { usePage } from './PageContext'
import { Bottom } from './Bottom'
import { Q5Confirmation } from './Q5Confirmation'

export default function Center() {
    const pageNo = usePage()
    console.log(pageNo)

    const PageOnCenter = ({ val }) => {
        switch (val) {
            case 1: return <Q1 />;
            case 2: return <Q2 />;
            case 3: return <Q3 />;
            case 4: return <Q4Send />
            case 5: return <Q5Confirmation />
            default: return <Bottom />;
        }
    }

    return (
        <>
            <span className='center-component'>
                <PageOnCenter val={pageNo} />
            </span>
        </>
    )
}
