import React from 'react'
import { motion, useAnimation } from "framer-motion/dist/framer-motion";
import Card from 'react-bootstrap/Card';

export const Q5Confirmation = () => {

   // Framer motion animation variables
   const controls = useAnimation()

   // animations settings on initial state
   const variants = {
       initial: {
           x: -1400
       }
   }
   // animation after load and forward Click on previous site
   controls.start({
       x: [1400, 0],
       transition: { duration: 1 }
   })

    return (
        <motion.div
        variants={variants}
        initial='initial'
        animate={controls}
        >
            <Card className='cards'>                
                <Card.Body className='card-body-confirm'>
                    <Card.Title>BEFRAGUNG ABGESCHICKT</Card.Title>
                    <Card.Text>
                        DANKE!
                    </Card.Text>  
                    <h4>This is a test website!</h4>
                    <p className='modal-text'>  You can freely enter various data into the forms.
                        The site does not remember or send any messages.
                        The website is created only for testing purposes
                        and to show in which technologies I write programs.
                        On this site I've used 
                        <span style={{fontWeight: 'bold'}}>
                        <li>React-Bootstrap,</li>
                        <li> FramerMotion for animation,</li>
                        <li> CSS HTML.</li>
                        </span>                        
                        In React I've used Hooks such as;<br />
                        useState, useContext, useEffect.<br /><br />
                        The website is on the Apache2 server on the UBUNTU system.<br/>
                        The website is secured using let's encrypt SSL certificate.
                        <br/>
                        <span style={{fontWeight: 'bold'}}>Server space is purchased on IONOS for € 1 per month!</span><br/>
                        (Server parameters: VPS - CPU 1 vCore, RAM 512MB, SSD 10GB)</p>                
                </Card.Body>
            </Card>
        </motion.div>
    )
}
