import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import { Bottom } from './components/Bottom';
import Center from './components/Center';
import { Head } from './components/Head';
import { Cookies } from './cookies/Cookies';

import { PageProvider } from './components/PageContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DataProvider } from './data-context/DataContext';

function App() {

  return (
    <div className='main-container'>
      <DataProvider>
        <PageProvider>
          <Container >
            <Cookies />        
            <Head />
            <Center />
            <Bottom />
          </Container>
        </PageProvider>
      </DataProvider>
    </div>
  );
}

export default App;
