import React, { useContext } from 'react'
import Form from 'react-bootstrap/Form';
//import Card from 'react-bootstrap/Card';
import { DataContext } from '../data-context/DataContext';
import Card from 'react-bootstrap/Card';

export const Q2Form = () => {
    // Data from DataContext GLOBAL VARIABLE
    const { gutachten, setGutachten, checkedArr, setCheckedArr } = useContext(DataContext)
    const handleChecks = e => {
        console.log(checkedArr.length)
        const checkedBoxID = e.target.id        
        let arrayOnForm = [...checkedArr]
        arrayOnForm[checkedBoxID] = arrayOnForm[checkedBoxID] === false ? true : false
        setCheckedArr(arrayOnForm)        

        const valueForm = e.target.name
        if (gutachten.includes(valueForm)) {
            setGutachten(gutachten.filter(item => item !== valueForm))
        } else {
            setGutachten([...gutachten, valueForm])
        }
        console.log(gutachten)
    }

    return (
        <Form>
            {/* <Form.Group className="mb-3" controlId="formBasicCheckbox"> */}
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Card  className='cards-form'>
                    <Card.Body className='cards-body'>
                        <span> Modul M1 Mobilität (10 %) – Beispiele:</span>
                        <Form.Check type="checkbox" onChange={handleChecks} name='Fortbewegung innerhalb des Wohnbereichs' label='Fortbewegung innerhalb des Wohnbereichs' checked={checkedArr[0]} id='0' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Schlafenlegen und Aufstehen' label='Schlafenlegen und Aufstehen' checked={checkedArr[1]} id='1' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Treppensteigen' label='Treppensteigen' checked={checkedArr[2]} id='2' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Körperhaltung ändern' label='Körperhaltung ändern' checked={checkedArr[3]} id='3' />
                    </Card.Body>
                </Card>
                <Card  className='cards-form'>
                    <Card.Body className='cards-body'>
                        <span>Modul M2 Kognitive und kommunikative Fähigkeiten (15 %) – Beispiele:</span>
                        <Form.Check type="checkbox" onChange={handleChecks} name='Räumliche und zeitliche Orientierung' label='Räumliche und zeitliche Orientierung' checked={checkedArr[4]} id='4' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Menschen erkennen' label='Menschen erkennen' checked={checkedArr[5]} id='5' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Gesprächspartner verstehen' label='Gesprächspartner verstehen' checked={checkedArr[6]} id='6' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Sachverhalte begreifen' label='Sachverhalte begreifen' checked={checkedArr[7]} id='7' />
                    </Card.Body>
                </Card>
                <Card  className='cards-form'>
                    <Card.Body className='cards-body'>
                        <span>Modul M3 Verhaltensweisen und psychische Problemlagen (15 %) – Beispiele:</span>
                        <Form.Check type="checkbox" onChange={handleChecks} name='Ängste und Aggressionen' label='Ängste und Aggressionen' checked={checkedArr[8]} id='8' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Abwehr pflegerischer Hilfen' label='Abwehr pflegerischer Hilfen' checked={checkedArr[9]} id='9' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Nächtliche Unruhe' label='Nächtliche Unruhe' checked={checkedArr[10]} id='10' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Wahnvorstellungen' label='Wahnvorstellungen' checked={checkedArr[11]} id='11' />     
                    </Card.Body>
                </Card>
                <Card  className='cards-form'>
                    <Card.Body className='cards-body'>
                        <span> Modul M4 Selbstversorgung (40 %) – Beispiele:</span>
                        <Form.Check type="checkbox" onChange={handleChecks} name='Selbstständig essen und trinken' label='Selbstständig essen und trinken' checked={checkedArr[12]} id='12' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Selbstständig die Toilette benutzen' label='Selbstständig die Toilette benutzen' checked={checkedArr[13]} id='13' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Selbstständig waschen' label='Selbstständig waschen' checked={checkedArr[14]} id='14' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Selbstständig ankleiden' label='Selbstständig ankleiden' checked={checkedArr[15]} id='15' />
                    </Card.Body>
                </Card>
                <Card  className='cards-form'>
                    <Card.Body className='cards-body'>
                        <span> Modul M5 Umgang mit krankheits- und therapiebedingten Anforderungen und Belastungen (20 %) – Beispiele: </span>
                        <Form.Check type="checkbox" onChange={handleChecks} name='Medikamente selbstständig nehmen ' label='Medikamente selbstständig nehmen ' checked={checkedArr[16]} id='16' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Allein zum Arzt gehen' label='Allein zum Arzt gehen' checked={checkedArr[17]} id='17' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Blutzucker selbst messen' label='Blutzucker selbst messen' checked={checkedArr[18]} id='18' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Mit Hilfsmitteln wie einem Rollator umgehen' label='Mit Hilfsmitteln wie einem Rollator umgehen' checked={checkedArr[19]} id='19' />
                    </Card.Body>
                </Card>
                <Card  className='cards-form'>
                    <Card.Body className='cards-body'>
                        <span> Modul M6 Gestaltung des Alltagslebens und sozialer Kontakte (15 %) – Beispiele:  </span>
                        <Form.Check type="checkbox" onChange={handleChecks} name='Tagesablauf selbstständig gestalten ' label='Tagesablauf selbstständig gestalten ' checked={checkedArr[20]} id='20' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Kontakt mit Menschen aufnehmen ' label='Kontakt mit Menschen aufnehmen ' checked={checkedArr[21]} id='21' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Kontakte zu Freunden pflegen' label='Kontakte zu Freunden pflegen' checked={checkedArr[22]} id='22' />
                        <Form.Check type="checkbox" onChange={handleChecks} name='Selbstständige Teilhabe an sozialen Veranstaltungen' label='Selbstständige Teilhabe an sozialen Veranstaltungen' checked={checkedArr[23]} id='23' />
                    </Card.Body>
                </Card>
            </Form.Group>
        </Form >
    )
}
