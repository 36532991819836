import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion/dist/framer-motion";
import {title, titleLine} from './HeadTitelPathSVG'

export const HeadTitel = () => {
    let d = title
    let dLine = titleLine

    const [isMove, setIsMove] = useState(true)
    const controlsText = useAnimation();
    const controlsLine = useAnimation(); 
    // EFFECT FOR Title
     const variantsText = {
        initial: {
            pathLenght: 0,
            //opacity: 1,
        }
    }
    useEffect(() => {
        if (isMove) {
            controlsText.start({
                pathLength: [0,1,1,1],
                transition: { duration: 50},
            })
        }
    }, [isMove, controlsText])
    useEffect(() => {
        if (isMove) {
            controlsLine.start({
                pathLength: [0,1,1,1],
                transition: { duration: 20},
            })
        }
    }, [isMove, controlsText])

    // Repeating interval for all with 'isMove' animations effects
    useEffect(() => {
        const timerCardOut = setInterval(() => {
            setIsMove(state => !state)
        }, 4000);
        return () => {
            clearInterval(timerCardOut);
        }
    }, [setIsMove])
    return (
        <>
            <svg 
                width="60vw" height="10vw" viewBox="0 0 420 30" 
                //fill='#aceba9'
                fill='rgb(245, 245, 245)'
                strokeWidth="2"
                //stroke= 'rgb(51, 121, 58)'
                 stroke="rgb(202, 202, 202)"
                 //stroke="yellow"
                strokeLinecap="round"    
            >
                <motion.path 
                className='head-titel-line'
                d={dLine} vector-effect="non-scaling-stroke" 
                variants={variantsText}
                initial='initial'
                animate={controlsLine}
                stroke='lightGreen'
                strokeWidth="1"
                //  fill='lightGrey'
                />
                <motion.path 
                className='head-titel'
                d={d} vector-effect="non-scaling-stroke" 
                variants={variantsText}
                initial='initial'
                animate={controlsText}                 
                />                                
            </svg>
        </>
    )
}
