import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion/dist/framer-motion";
import {esculape} from './EsculapeSVGPath'

export default function EsculapeInCircle() {
    let width = 100
    let height = 100

    const controlsOuter = useAnimation();
    const controlsInner = useAnimation();
    const controlsText = useAnimation();
    const [isMove, setIsMove] = useState(true);

    // Repeating interval for all with 'isMove' animations effects
    useEffect(() => {
        const timerCardOut = setInterval(() => {
            setIsMove(state => !state)
        }, 5000);
        return () => {
            clearInterval(timerCardOut);
        }
    }, [setIsMove])

    // EFFECT FOR LETTER IN A CIRCLE
    const variantsText = {
        initial: {
            //pathLenght: 1,
            opacity: 1,
        }
    }
    useEffect(() => {
        if (isMove) {
            controlsText.start({
                pathLength: [1, 0, 1],
                transition: { duration: 3 },
            })
        }
    }, [isMove, controlsText])


    // Initial state for both effects on circles
    const variants = {
        initial: {
            pathLenght: 0.8,
            opacity: 1,
        }
    }
    // EFFECT FOR Outer Circle
    useEffect(() => {
        if (isMove) {
            controlsOuter.start({
                opacity: 1,
            })
            controlsOuter.start({
                pathLength: [0.8, 0.3, 0.8],
                transition: { duration: 3 },
            })
        }       
    }, [isMove, controlsOuter])

    // EFFECT FOR inner Circle
    useEffect(() => {
        if (isMove) {
            controlsInner.start({
                opacity: 1,
            })
            controlsInner.start({
                pathLength: [0.8, 0.3, 0.8],
                transition: { duration: 3 },
            })
        }       
    }, [isMove, controlsInner])



    return (
        <>
            <svg
                viewBox="10 5 100 100"
                width={width} height={height}>
                {/* Esculape sign in a circle*/}
                <svg>
                    <motion.path                    
                        d={esculape}
                        fill="transparent"
                        strokeWidth="1"
                        stroke="green"
                        strokeLinecap="round"
                        variants={variantsText}
                        initial='initial'
                        animate={controlsText}
                    />
                </svg>
                {/* OUTER CIRCLE */}
                <motion.path
                    // d="M 50 10 C 100 10 100 80 50 80 C 0 80 0 10 50 10"
                    d='M 60 12 C 120 12 120 96 60 96 C 0 96 0 12 60 12'
                    fill="transparent"
                    strokeWidth="1"
                    stroke="lightGreen"
                    strokeLinecap="round"
                    variants={variants}
                    initial='initial'
                    animate={controlsOuter}
                />

                {/* SMALL CIRCLE */}
                <motion.path
                    //d="M 49 13 C 94 13 94 76 49 76 C 4 76 4 13 49 13"
                    //d='M 49 76 C 93 76 93 14 50 14 C 7 14 7 76 49 76'
                    d='M 58.8 91.2 C 111.6 91.2 111.6 16.8 60 16.8 C 8.4 16.8 8.4 91.2 58.8 91.2'
                    fill="transparent"
                    strokeWidth="1"
                    stroke="lightGreen"
                    strokeLinecap="round"
                    variants={variants}
                    initial='initial'
                    animate={controlsInner}
                />
            </svg>
        </>
    )
}
