import React, { useState } from 'react'
import { CookieMonster } from './CookieMonster';
import './cookies.css';
// import cookieMonster from '../graphics/cookieMonster.png'

export const Cookies = () => {
    const [show, setShow] = useState(true)
    const showCookies = () => {
        setShow(false)
    }

    return (
        <>
            {show ?

                <div className='cookies-container'>
                    <p className='cookies-title'>COOKIES, love to eat it :)</p>
                    <p className='cookies-text'>
                        Die Website, auf der Sie sich befinden, ist nur ein Beispiel für den Einsatz von Programmiertechnik,
                        daher müssen keine Cookies akzeptiert werden.
                        Wir speichern keine Daten,
                        aber Sie haben das Recht zu bestätigen oder abzulehnen.
                        Wir verfolgen niemanden und speichern keine sensiblen Daten.
                        Wir sind fest überzeugt, es ist besser,
                        kleine Cookies zu essen, als sich an alle zu erinnern.

                    </p>
                    <button onClick={showCookies}>Ja</button>
                    <button onClick={showCookies}>Nein</button>
                    <button onClick={showCookies}>...gerne Cookies essen</button>
                    <CookieMonster />

                </div>
                :
                ''
            }
        </>

    )
}
