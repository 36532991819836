import React, { useState, useEffect } from 'react'
import { motion, useAnimation } from "framer-motion/dist/framer-motion";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge'
import { usePage, useBack, usePageRight, usePageLeft } from './PageContext'
import { Q2Form } from './Q2Form';

export const Q2 = () => {

  // useContext variable and methods
  const updatePageRight = usePageRight()
  const updatePageLeft = usePageLeft()
  const pageNo = usePage()
  const backPressed = useBack()
  const timeAwait = 450

  // Framer motion animation variables
  const controls = useAnimation()
  const [moveRight, setMoveRight] = useState(false)
  const [moveLeft, setMoveLeft] = useState(false)

  // animations settings on initial state
  const variants = {
    initial: {
      x: -1400
    }
  }
  // animation after load and forward Click on previous site
  controls.start({
    x: [1400, 0],
    transition: { duration: 1 }
  })

  function moveRightFunc() {
    setMoveRight(state => !state)
  }
  function moveLeftFunc() {
    setMoveLeft(state => !state)
  }

  // animation after load and back Click on previous site
  useEffect(() => {
    if (backPressed) {
      controls.start({
        x: [-1400, 0],
        transition: { duration: 1 }
      })
    }
  }, [backPressed, controls])

  // animation after right click on button 'weiter'
  useEffect(() => {
    if (moveLeft) {
      controls.start({
        x: [0, -1400],
        transition: { duration: 1 }
      })
      const timerCardOut = setInterval(() => {
        updatePageRight()
      }, timeAwait);
      return () => {
        clearInterval(timerCardOut);
      }
    }
  }, [moveLeft, controls, updatePageRight])

  // animation after left button click 'zurück'
  useEffect(() => {
    if (moveRight) {
      controls.start({
        x: [0, 1400],
        transition: { duration: 1 }
      })
      const timerCardOut = setInterval(() => {
        updatePageLeft()
      }, timeAwait);
      return () => {
        clearInterval(timerCardOut);
      }
    }
  }, [moveRight, controls, updatePageLeft])



  return (
    <motion.div
      variants={variants}
      initial='initial'
      animate={controls}
    >
      <Card className='cards'>
        <Card.Body>
          <Card.Title><Badge bg="primary">{pageNo}</Badge> Pflegegutachten zur Zuordnung eines Pflegegrads </Card.Title>
          <Card.Text>
            Kreuzen Sie die für Ihre Situation zutreffenden Kästchen an.
          </Card.Text>
          <Q2Form />
          <div className='submit-buttons'>
            <Button variant="success" onClick={moveRightFunc}>zurück</Button>&nbsp;
            <Button variant="success" onClick={moveLeftFunc}>weiter</Button>
          </div>
        </Card.Body>
      </Card>
    </motion.div >
  )
}
